import React from 'react';
import { graphql, PageProps } from 'gatsby';
import PageBanner from '@/components/blog-hub-page-banner';
import Layout from '@/components/_layout';
import Seo from '@/components/_layout/seo';
import BlogArticleBody from '@/components/blog-article-body';
import Blog from '@/components/blog';
import { setBlogFeaturedData } from '@/utils/helper/blog';

const Article = ({
	data,
	location,
	pageContext,
}: PageProps<any>): JSX.Element => {
	const {
		// @ts-ignore
		breadcrumb: { crumbs },
	} = pageContext;

	let compiledText = [];
	data?.sanityBlog?._rawText.map((text) => {
		if (text.children) {
			compiledText.push(
				text.children
					.map((child) => {
						return child.text;
					})
					.join('')
			);
		}
	});

	return (
		<Layout
			location={location}
			crumbs={crumbs}
			crumbLabel={data?.sanityBlog?.title}
		>
			<Seo
				title={
					data?.sanityBlog?.metaInfo?.pageMetaName ||
					data?.sanityBlog?.title ||
					'Blog Article'
				}
				description={data?.sanityBlog?.metaInfo?.pageMetaDescription}
				pageMetadata={data?.sanityBlog?.metaInfo?.metadata}
				canonical={data?.sanityBlog?.metaInfo?.canonical}
				opengraphImage={
					data?.sanityBlog?.metaInfo?.opengraphImage
						?.asset?.url
				}
			/>

			<script type='application/ld+json'>
				{JSON.stringify({
					'@context': 'https://schema.org',
					'@type': 'BlogPosting',
					headline: `${data?.sanityBlog?.title}`,
					image: `${data?.sanityBlog?.banner?.asset?.gatsbyImageData?.images?.fallback?.src}`,
					genre: `${data?.sanityBlog?.category?.title}`,
					url: `${data?.site?.siteMetadata?.siteUrl}/blog/${data?.sanityBlog?.slug?.current}/`,
					datePublished: `${data?.sanityBlog?.isoDate}`,
					dateCreated: `${data?.sanityBlog?.isoDate}`,
					dateModified: `${data?.sanityBlog?.isoDateUpdated}`,
					description: `${data?.sanityBlog?.metaInfo?.pageMetaDescription}`,
					articleBody: `${compiledText.join('\n')}`,
					author: {
						'@type': 'Person',
						name: `${data?.sanityBlog?.author?.name}`,
					},
				})}
			</script>
			<PageBanner
				page='ARTICLE'
				title={{ solid: data?.sanityBlog?.title }}
				imageUrl={data?.sanityBlog?.banner}
				isGradient={false}
				// reactionStats={{
				//   celebrate: data?.sanityBlog?.love_count,
				//   love: data?.sanityBlog?.love_count,
				//   like: data?.sanityBlog?.like_count,
				// }}
				bannerImgClass={`h-full opacity-50 object-cover`}
				isDetailsPage
			/>
			<BlogArticleBody
				user={{
					avatar: data?.sanityBlog?.author?.photo,
					category: data?.sanityBlog?.category?.title,
					published: data?.sanityBlog?._createdAt,
					updated: data?.sanityBlog?._updatedAt,
					timeAgo: data?.sanityBlog?.relativeDate,
					isoDate: data?.sanityBlog?.isoDate,
					name: data?.sanityBlog?.author?.name,
					isCurrentMember: data?.sanityBlog?.author?.is_current_member,
					jobTitle: data?.sanityBlog?.author?.position,
					contributors: data?.sanityBlog?.contributors
				}}
				readTime={data?.sanityBlog?.reading_time}
				blog={data?.sanityBlog?._rawText}
			/>
			{/* <Faporbaji
        backgroundImgUrl={YOU_THINK_IMAGE}
        backgroundPosition="top"
        isDark={true}
        title={{ solid: 'SO, WHAT D’YA THINK?' }}
        headerClass="md:w-10/12 mx-auto text-center"
        body={<YouThinkContent icons={youThinkBody} />}
        contentClass="pt-4 text-center"
      /> */}
			<div className='relative bg-white'>
				<Blog
					blogs={
						data?.sanityOurBlogs?.blogs ||
						setBlogFeaturedData(data?.allSanityBlog?.edges)
					}
					title={{ solid: 'READ MORE FROM OUR BLOG' }}
				/>
			</div>
		</Layout>
	);
};

export default Article;

export const query = graphql`
	query sanityBlog($id: String!) {
		site {
			siteMetadata {
				siteUrl
			}
		}
		sanityBlog(id: { eq: $id }) {
			id
			slug {
				current
			}
			metaInfo {
				pageMetaDescription
				pageMetaName
				metadata {
					metaContent
					metaName
				}
				canonical
				opengraphImage {
					asset {
						url
					}
				}
			}
			excerpt
			title
			_rawText
			category {
				id
				title
			}
			reading_time
			clap_count
			like_count
			love_count
			author {
				name

				photo {
					asset {
						gatsbyImageData(placeholder: BLURRED)
					}
				}
				social_media {
					youtube
					twitter
					tiktok
					linkedin
					instagram
					facebook
				}
				position
				is_current_member
			}
			contributors {
				name

				photo {
					asset {
						gatsbyImageData(placeholder: BLURRED)
					}
				}
				social_media {
					youtube
					twitter
					tiktok
					linkedin
					instagram
					facebook
				}
				position
				is_current_member
			}
			thumbnail {
				asset {
					gatsbyImageData(placeholder: BLURRED)
				}
			}
			banner {
				asset {
					gatsbyImageData(placeholder: BLURRED)
				}
			}
			_createdAt(formatString: "dddd, Do MMMM YYYY")
			_updatedAt(formatString: "dddd, Do MMMM YYYY")
			relativeDate: _createdAt(fromNow: true)
			isoDate: _createdAt(formatString: "YYYY-MM-DD")
			isoDateUpdated: _updatedAt(formatString: "YYYY-MM-DD")
		}
		allSanityBlog(limit: 3, sort: { fields: _createdAt, order: DESC }) {
			edges {
				node {
					id
					slug {
						current
					}
					title
					excerpt
					thumbnail {
						asset {
							gatsbyImageData(placeholder: BLURRED)
						}
					}
				}
			}
		}
		sanityOurBlogs {
			title {
				title_solid
				title_outlined
				is_outline_first
			}
			blogs {
				love_count
				like_count
				clap_count
				blog {
					id
					slug {
						current
					}
					title
					excerpt
					thumbnail {
						asset {
							gatsbyImageData(placeholder: BLURRED)
						}
					}
				}
			}
		}
	}
`;
