import useWindowDimensions from '@/utils/hooks';
import React, { ReactNode } from 'react';
import { Container, Swiper } from '@/components/_root';
import HoverList from '../hover-list';

interface CarouselOrGridProps {
  children: ReactNode;
  gridItemCount?: number;
  withHoverList?: boolean;
  showItems?: { sm: number; md: number; lg: number; xl: number };
}

const CarouselOrGrid = ({
  children,
  gridItemCount = 3,
  withHoverList,
  showItems,
}: CarouselOrGridProps): JSX.Element => {
  const { width } = useWindowDimensions();

  if (width > 768) {
    return (
      <Container>
        {withHoverList ? (
          <HoverList className={`grid grid-cols-${gridItemCount} gap-14`}>{children}</HoverList>
        ) : (
          <div className={`grid grid-cols-${gridItemCount} gap-14`}>{children}</div>
        )}
      </Container>
    );
  }
  return (
    <Swiper
      showBleeding
      haveOffset
      // showNavigation
      spaceBetween={{ sm: 24, md: 24, lg: 24, xl: 28 }}
      showItems={showItems}
    >
      {children}
    </Swiper>
  );
};

export default CarouselOrGrid;
