import React, { ReactNode } from 'react';
import { PageSection } from '@/components/_root';
import { ArticleContainer, Avatar, AvatarWrapper } from './styled';
import BlockContent from '@/components/block-content';

import TikTokSEOSection from '@/components/tiktok-seo-section';
import { getTitle } from '@/utils/helper/getTitle';

interface User {
	name: string;
	category: string;
	published: string;
	timeAgo: string;
	isoDate: string;
	updated: string;
	avatar: string;
	isCurrentMember: boolean;
	jobTitle: string;
	contributors: string;
}

interface Props {
	user: User;
	readTime: number;
	blog: ReactNode;
}

const BlogArticleBody = ({ user, readTime, blog }: Props): JSX.Element => {
	const blogPath = typeof window !== 'undefined' ? window.location.pathname : '';

	return (
		<PageSection noScrollReveal>
			<ArticleContainer>
				<AvatarWrapper>
					<Avatar user={user} readTime={readTime} blog={blog} />
				</AvatarWrapper>
				<BlockContent className={'article'} blocks={blog} />

				{/* <ButtonWrapper>
			<div className="w-44">
			<Button mode="light" size="medium" href="/" block={true} hoverVersion="bordered">
			Primary CTA
			</Button>
			</div>
			<div className="w-44">
			<Button type="secondary" mode="light" size="medium" href="/" block={true}>
			Secondary CTA
			</Button>
			</div>
			</ButtonWrapper> */}
			</ArticleContainer>
			{/* {blogPath == '/blog/the-ultimate-guide-to-tiktok-seo/' && (
				<TikTokSEOSection
					title={getTitle({
						title: {
							is_outline_first: true,
							title_outlined: "TikTok traffic on your site",
							title_solid: "Seven ways to convert"
						}
					})}
					description={"The definitive guide to converting TikTok demand and traffic on your website"}
					bookImageUrl={"https://cdn.sanity.io/images/fxdosu8c/development/3b413a8aba9006a494e8d7d69c3dc306bb68fce2-800x832.png?w=500&fit=max&auto=format&dpr=2"}
					actionButton={{
						title: "Get the guide",
						url: "/"
					}}
					downloadLink={"https://cdn.sanity.io/files/fxdosu8c/development/19a5f4d275ca9fbb60466cca5f6b56150de738a4.pdf"}
				/>
			)} */}
		</PageSection>
	)
};

export default BlogArticleBody;