import React, { ReactNode } from 'react';
import { Image } from '@/components/_root';
import { ClockIcon } from '@/components/_icons';

interface Props {
	children: ReactNode;
}
interface User {
	name: string;
	avatar: string;
	isCurrentMember: boolean;
	published: string;
	updated: string;
	timeAgo: string;
	isoDate: string;
	jobTitle: string;
	contributors: Array<object>
}

interface AvatarProps {
	user: User;
	readTime: number;
	published: string;
	blog: any;
}

const getPlainTextBlogPost = (blog: Array<object>) => {
	let plainText = '';

	blog.forEach((block: any) => {
		block.children?.forEach((child: Array<object>) => {
			if (child.text) {
				plainText += ` ${child.text}`;
			}
		});
	});

	return plainText;
};

const calculateReadTimeForBlogPost = (text: string) => {
	const wpm = 225;
	const words = text.trim().split(/\s+/).length;
	const time = Math.ceil(words / wpm);

	return time;
};

export const ArticleContainer = ({ children }: Props): JSX.Element => (
	<>
		{/* <div className="px-8 lg:px-0 lg:w-2/3 xl:w-1/2 lg:mx-auto">{children}</div> */}
		<div className='px-6 mx-auto max-w-200'>{children}</div>
	</>
);

export const AvatarWrapper = ({ children }: Props): JSX.Element => (
	<div className='flex flex-col pb-12 space-y-6 md:flex-row md:space-x-8 md:space-y-0 md:pb-16'>
		{children}
	</div>
);

export const Avatar = ({ user, readTime, blog }: AvatarProps): JSX.Element => {
	
	return (
	<>
		{user.contributors.length > 0 ? (
			<div className='flex items-center space-x-4'>
				<p className='leading-none'>
					By {user.name.trim()}
					{
						user.contributors.map((contributor: object, key: number) => {
							if (key < (user.contributors.length-1)) {
								return `, ${contributor.name}`
							} else {
								return ` & ${contributor.name}`
							}
						}).join('')
					}
					{user.jobTitle && (
						<span className='block text-sm font-bold'>
							{user.jobTitle}
							{
								user.contributors.map((contributor: object, key: number) => {
									if (contributor.position !== null) {
										if (key < (user.contributors.length - 1)) {
											return `, ${contributor.position}`
										} else {
											return ` & ${contributor.position}`
										}
									}
								}).join('')
							}
						</span>
					)}
				</p>
			</div>
		) : 
			user.name && user.avatar ? (
				<div className='flex items-center space-x-4'>
					<Image
						src={user.avatar}
						alt='avatar'
						className='object-fill w-12 h-12 rounded-full'
					/>
					<p className='leading-none'>
						{user.name}{' '}
						{user.jobTitle && (
							<span className='block text-sm font-bold'>{user.jobTitle}</span>
						)}
					</p>
				</div>
			) : (
				<div className='flex items-center space-x-4'>
					<p className='leading-none'>
						By {user.name}{' '}
						{user.jobTitle && (
							<span className='block text-sm font-bold'>{user.jobTitle}</span>
						)}
					</p>
				</div>
			)
		}
	
		{readTime ? (
			<div className='flex items-center space-x-4'>
				<ClockIcon />
				<p>{readTime} min read</p>
			</div>
		) : (
			<div className='flex items-center space-x-4'>
				<ClockIcon />
				<p>
					{calculateReadTimeForBlogPost(getPlainTextBlogPost(blog))} min read
				</p>
			</div>
		)}
		{user.published && (
			<div className='flex items-center space-x-4'>
				<p>{user.updated ? user.updated : user.published}</p>
			</div>
		)}
	</>
)};

export const ButtonWrapper = ({ children }: Props): JSX.Element => (
	<div className='hidden pt-10 space-x-8 md:flex'>{children}</div>
);
